/* eslint-disable */
function getQueryParameters() {
    var query = window.location.search;
    var urlSearchParams = new URLSearchParams(query[0] === '?' ? query.slice(1) : query);
    var params = {};
    for (var key of urlSearchParams.keys()) {
        params[key] = urlSearchParams.get(key);
    }
    return params;
}

YP.TicketingManager = function() {
    var enterKeyCharCode = 13;
    var ticketingTable = $('#module-ticket table');
    var typeTicketSelectSeat = ticketingTable.find('select.nbSeat');
    var descriptionLink = $('.ticket-description-link');
    var notification = new Object();
    return {
        init: function() {
            this.displayBookingSubmitButton(true);
            typeTicketSelectSeat.on('change', this.changeSeat);
            typeTicketSelectSeat.change();
            descriptionLink.on('click', this.toggleTicketDescription);
            ticketingTable.on('click', 'tbody.category-label tr', this.closeCategory);
            ticketingTable.on('click', 'tbody.cat-close tr', this.openCategory);
            $('.categories-tabs li a').on('click', this.switchCategory);

            var ticketForm = $('form.ticket-event');
            if (ticketForm.length > 0) {
                // Handle promotion code validation by mouse click
                $('form.ticket-event .btn-click').click(function() {
                    var url = $(this).attr('href');
                    YP.TicketingManager().redirectPromo(url);
                    return false;
                });
                // Handle promotion code validation when user presses "Enter" key (charCode 13)
                $('form.ticket-event input[name=code]').on('keypress', function(e) {
                    if (e.which == enterKeyCharCode) {
                        var url = $(this).parent().find('a').attr('href');
                        YP.TicketingManager().redirectPromo(url);
                        return false;
                    }
                });
            }

            if ($('#switch-send-ticket-container').length) {
                $('#switch-send-ticket-container').on('click', function(e) {
                    e.preventDefault();
                    $(".toggle-icon", this).html() === "-" ? $(".toggle-icon", this).html('+') : $(".toggle-icon", this).html('-');
                    $('.send-ticket-container').slideToggle(100);
                });
                form = $('form.sendEmailForm');
                form.on('submit', {
                    klass: this
                }, this.sendEmailFormSubmit);
            }

            $('.js-file-upload').change(function() {
                var file = $('.js-file-upload')[0].files[0].name;
                $(this).next('.c-form-field__fileupload-info').text(file);
            });

            $('form.ticket-event').on('submit', function(e) {
                YP.TicketingManager().displayBookingSubmitButton(true);
            });

            //deny copy paste
            $('.email_field').bind("cut copy paste", function(e) {
                e.preventDefault();
            });
            $('.email_confirm_field').bind("cut copy paste", function(e) {
                e.preventDefault();
            });
            var queryParameters = getQueryParameters();
            var isContactOrgaModalDisplayed = !!Number.parseInt(queryParameters['contact-orga-modal'], 10);
            if (isContactOrgaModalDisplayed) {
                $('.js-contact-orga-modal').trigger('click');
            }

            // Handle promo code deletion
            var removePromoCodeLink = $('#reset_promotion_code');
            if (removePromoCodeLink.length) {
                removePromoCodeLink.prop('href', removePromoCodeLink.prop('href').replace(removePromoCodeLink.data('code'), ''));
            }
        },
        displayBookingSubmitButton: function(disabled) {
            $("#booking-tickets").attr("disabled", disabled);
            if (disabled) {
                $("#booking-tickets").addClass("u-tips u-tips--left");
            } else {
                $("#booking-tickets").removeClass("u-tips u-tips--left");
            }
        },
        sendEmailFormSubmit: function(e) {
            e.preventDefault();
            form = $('form.sendEmailForm');
            form.find("button:submit").attr('disabled', 'disabled');
            form.find("button:submit").html('<i class="fa fa-spinner fa-spin icon-large"></i>');
            var options = {
                dataType: 'json',
                success: function(data) {
                    form.find("button:submit").removeAttr('disabled');
                    form.find("button:submit").html('Envoyer vos billets');
                    if (data.status == "error") {
                        form.find('fieldset').html(data.view);
                    } else {
                        if (!$('.widget').length) {
                            notification.message = data.message;
                            notification.class = "valid";
                            YP.NotifManager().show(notification);
                        }
                        $('.send-ticket-container').slideToggle();
                        if ($('.widget').length) {
                            $notice = $('<div class="alert alert-success col-md-12 col-xs-12"><i class="fa fa-check-circle-o"></i>&nbsp;' + data.message + '</div>');
                            $notice.insertAfter($('.send-ticket-container'));
                            $notice.fadeOut(3000);
                        }
                    }
                },
                error: function(data) {
                    form.find("button:submit").removeAttr('disabled');
                    form.find("button:submit").html('Envoyer vos billets');
                    notification.message = "Une erreur est survenue lors de la validation du formulaire";
                    notification.class = "error";
                    YP.NotifManager().show(notification);
                }
            };
            $(this).ajaxSubmit(options);
        },
        redirectPromo: function(url) {
            var code = $('form.ticket-event input[name=code]').val();
            if($('.c-container-btn-seating').length) {
                if($('.seating__choice_chart').hasClass('is-active')) {
                    code = $('#chart_cart input[name=code]').val();
                }else {
                    code = $('#print-table-ticket input[name=code]').val();
                }
            }
            if (code) {
                newurl = '';
                if (url.indexOf('?') == -1) {
                    newurl = url + '?code=' + encodeURIComponent(code);
                } else {
                    newurl = url + '&code=' + encodeURIComponent(code);
                }
                $(location).attr('href', newurl);
            }
            return false;
        },
        rewriteCodeUrl: function(url) {
            var url = $('.total-ticket').data('url');
            newurl = '';
            if (url.indexOf('?') == -1) {
                newurl = url + '?' + $('.ticket-event select').serialize();
            } else {
                newurl = url + '&' + $('.ticket-event select').serialize();
            }

            $('.apply-promocode').attr('href', newurl);

        },
        changeSeat: function(e) {
            YP.TicketingManager().rewriteCodeUrl();
            var nbTicket = $(this).val();
            var $table = $(this).parents('table');
            var price = parseFloat($(this).parents('tr').find('.price span.val span').text().replace(/\,/g, ''));
            var hasPromoPrice = $(this).parents('tr').find('.price span.promo-price').length > 0;
            var isFree = $(this).parents('tr').find('.price span.promo-price').data("free");
            var promoPrice = parseFloat($(this).parents('tr').find('.price span.promo-price span').text().replace(/\,/g, ''));
            var globalPromoPriceFixe = parseFloat($('.promo-payment .value-fixe').text());
            var globalPromoPricePercent = $('.promo-payment .value-percent').text();
            var globalPromoPriceFree = $('.promo-payment .value-free').text();
            var limitPromo = $(this).parents('tr').find('.price span.promo-price').data('limit');
            var $total = $(this).parents('tr').find('.total .change_price');
            var $totalPromo = $(this).parents('tr').find('.total span.promo-price span');
            var $grandTotal = $table.find('tfoot .grandtotal span');
            var grandTotal = 0;
            var grandTotalPromo = 0;
            var promo = 0;
            var $htTotal = $table.find('tfoot .httotal');

            if (price == '') {
                return;
            }

            if (!hasPromoPrice) {
                promoPrice = 0;
            } else if (isFree) {
                promoPrice = price;
            }

            var total = nbTicket * price;

            if (limitPromo) {
                var withoutPromo = nbTicket > limitPromo ? nbTicket - limitPromo : 0;
                var totalPromo = ((nbTicket - withoutPromo) * promoPrice) + (withoutPromo * price);
            } else {
                var totalPromo = nbTicket * promoPrice;
            }

            $total.text(total.toFixed(2));
            if (totalPromo == 0 && !hasPromoPrice || nbTicket <= 0 || totalPromo < 0) {
                $totalPromo.parent().hide();
                $total.parent().parent().removeClass('barre');
            } else {
                $totalPromo.text(totalPromo.toFixed(2));
                $totalPromo.parent().show();
                $total.parent().parent().addClass('barre');
            }

            $table.find('tbody .total .promo-price span').each(function() {
                grandTotalPromo = grandTotalPromo + parseFloat($(this).text());
                if (parseFloat($(this).parent().parent().find('.change_price').text()) > 0) {
                    if (parseFloat($(this).parent().parent().find('.change_price').text()) === parseFloat($(this).text())) {
                        promo += parseFloat($(this).text())
                    } else {
                        promo += (parseFloat($(this).parent().parent().find('.change_price').text()) - parseFloat($(this).text()));
                    }
                }
            });

            $table.find('tbody .total .change_price').each(function() {
                pp = parseFloat($(this).text());
                if (!isNaN(pp)) {
                    grandTotal = grandTotal + parseFloat($(this).text());
                }
            });

            if (grandTotalPromo >= 0) {
                grandTotal = grandTotal - promo;
            }

            if (promo > 0) {
                $table.find('.promo-reduc').show();
                $table.find('.promo-reduc span').text('-' + promo.toFixed(2));
            } else {
                $table.find('.promo-reduc').hide();
                $table.find('.promo-reduc span').text(0);
            }

            if ($htTotal.length > 0) {
                var vat = $htTotal.data('tva');
                var ttcValue = (parseFloat(grandTotal) + (parseFloat(grandTotal * parseFloat(vat))) / 100);
                $grandTotal.text(Math.round(ttcValue.toFixed(2)));
                $htTotal.find('span').text(grandTotal);
            }

            if (globalPromoPriceFixe || globalPromoPricePercent || globalPromoPriceFree) {
                if (globalPromoPriceFixe) {
                    reduc = globalPromoPriceFixe;
                    if ($htTotal.length > 0) {
                        reduc = globalPromoPriceFixe / (1 + parseFloat(vat) / 100);
                    }
                } else if (globalPromoPricePercent) {
                    reduc = grandTotal * (globalPromoPricePercent / 100)
                } else if (globalPromoPriceFree) {
                    reduc = grandTotal
                }

                newGrandTotal = grandTotal - reduc > 0 ? grandTotal - reduc : 0;
                $grandTotal.text(newGrandTotal.toFixed(2));

                if ($htTotal.length > 0) {
                    $htTotal.find('span').text(newGrandTotal.toFixed(2));
                    $grandTotal.text(Math.round((parseFloat(newGrandTotal) * (1 + parseFloat(vat) / 100)).toFixed(2)));
                }

                if (reduc > 0) {
                    $table.find('.promo-reduc').show();
                    $table.find('.promo-reduc span').text('-' + parseFloat(reduc).toFixed(2));
                } else {
                    $table.find('.promo-reduc').hide();
                    $table.find('.promo-reduc span').text(0);
                }
            } else {
                if ($htTotal.length > 0) {
                    $grandTotal.text((parseFloat(grandTotal) * (1 + parseFloat(vat) / 100)).toFixed(2));
                    $htTotal.find('span').text(grandTotal.toFixed(2));
                } else {
                    $grandTotal.text(grandTotal.toFixed(2));
                }
            }

            if (grandTotal > 0) {
                $table.find('.tfoot-ticketing .grandtotal').show();
            } else {
                $table.find('.tfoot-ticketing .grandtotal').hide();
            }


            const ticketCount = typeTicketSelectSeat.toArray().reduce(function(sum,element) {
                return sum + Number(element.value);
            }, 0) + $('.chart_cart tbody tr').length;
            YP.TicketingManager().displayBookingSubmitButton(ticketCount < 1);
            YP.TicketingManager().handleGlobalLimit($(this));
        },
        toggleTicketDescription: function(e) {
            e.preventDefault();
            var el = $(this),
            desc = $('.ticket-description[data-id="' + $(this).data('id') + '"]');
            desc.toggle();
            if (desc.is(':visible')) {
                $('#ticket-description-down-' + $(this).data('id')).addClass('hide');
                $('#ticket-description-up-' + $(this).data('id')).removeClass('hide');
            } else {
                $('#ticket-description-down-' + $(this).data('id')).removeClass('hide');
                $('#ticket-description-up-' + $(this).data('id')).addClass('hide');
            }
        },
        closeCategory: function(e) {
            $(this).parent().addClass('cat-close');
            $(this).parent().next().addClass('cat-close');
            $(this).parent().find('i').attr('class', 'fa fa-caret-right');
            $(this).parent().next().slideUp(100);
        },
        openCategory: function(e) {
            $(this).parent().removeClass('cat-close');
            $(this).parent().next().removeClass('cat-close');
            $(this).parent().find('i').attr('class', 'fa fa-sort-desc');
            $(this).parent().next().slideDown(100);
        },
        switchCategory: function(e) {
            e.preventDefault();
            $(this).parent().addClass("active");
            $(this).parent().siblings().removeClass("active");
            if ($(this).parent().hasClass('main')) {
                $('.total-ticket tbody').show();
                $('tbody.category-label').show();
                $('tbody.category-container').show();
                $('tbody.no-category').show();
            } else {
                $('tbody.category-label[id!="' + $(this).parent().data('id') + '"]').hide();
                $('tbody.category-container[id!="' + $(this).parent().data('id') + '"]').hide();
                $('tbody.category-label[id="' + $(this).parent().data('id') + '"]').show();
                $('tbody.category-container[id="' + $(this).parent().data('id') + '"]').show();
                $('tbody.category-label').hide();
                $('tbody.no-category').hide();
            }
            $('tbody.category-label').find('i').attr('class', 'fa fa-sort-desc');
            $('tbody.category-label').removeClass('cat-close');
        },
        handleGlobalLimit: function(elem) {
            const globalLimit = Number($('#global_ticketing_vars').data('left-tickets'));
            const hasCategoryLimit = Boolean($('.nbSeat[data-category]').length);

            if (isNaN(globalLimit) && !hasCategoryLimit) {
                return;
            }

            const totalSelect  = typeTicketSelectSeat.toArray().reduce(function(sum,element) {
                return sum + Number(element.value);
            }, 0);

            typeTicketSelectSeat.not(elem).each(function() {
                const curVal          = Number($(this).val());
                const minVal          = Number($(this).data('min'));
                const maxVal          = Number($(this).data('max'));
                const categoryId      = Number($(this).data('category'));
                const categoryMaxVal  = $(this).data('category-max') ? Number($(this).data('category-max')) : null;
                const categoryMinVal  = $(this).data('category-min') ? Number($(this).data('category-min')) : null;
                const categoryQuota   = $(this).data('category-quota') ? Number($(this).data('category-quota')) : null;
                var nbrOption         = 0;
                var categoryLimit     = Math.min.apply(Math, [categoryMaxVal, categoryQuota].map(function(val) {
                    return val == null ? Infinity : val;
                }));
                if(categoryLimit !== null) {
                    var typeTicketCategories = $('.nbSeat[data-category="'+ categoryId +'"]');
                    var categorySelect = typeTicketCategories.toArray().reduce(function(sum,element) {
                        return sum + Number(element.value);
                    }, 0);
                    if(globalLimit) {
                        var maxLeft = Math.min(categoryLimit - categorySelect, globalLimit - totalSelect);
                    } else {
                        var maxLeft = Math.min(categoryLimit - categorySelect);
                    }
                } else if(!isNaN(globalLimit)) {
                    var maxLeft = globalLimit - totalSelect;
                }
                if(isNaN(maxLeft)) {
                    return;
                }
                maxLeft += curVal;
                $(this).empty();

                if (maxLeft > 0) {
                    if (maxLeft <= maxVal && maxLeft >= minVal) {
                        nbrOption = maxLeft;
                    }

                    if (maxLeft < minVal) {
                        nbrOption = 0;
                    }

                    if (maxLeft > maxVal) {
                        nbrOption = maxVal;
                    }
                }

                if (minVal > 0 || nbrOption == 0) {
                    $(this).append($('<option>', {
                        value: 0,
                        text : 0
                    }));
                }

                if(nbrOption > 0) {
                    for (var i = minVal; i <= nbrOption; i++) {
                        $(this).append($('<option>', {
                            value: i,
                            text : i
                        }));
                    }
                }

                if (curVal) {
                    $(this).val(curVal);
                }
            })
        },
    }
};

window.addEventListener("load", function() {
    YP.TicketingManager().init();
    // Timer ticketing
    var ticketingTimer = $('.ticketing_timer');
    if (ticketingTimer.length > 0) {
        var timeLeft = ticketingTimer.data('timeleft');
        var now = new Date();
        var endTime = new Date(now.getTime() + timeLeft * 1000);

        function displayTime(field, numSeconds) {
            timeMinutes = parseInt(numSeconds / 60);
            timeSeconds = parseInt(numSeconds - timeMinutes * 60);
            if (timeSeconds < 10) {
                timeLabel = timeMinutes + ":0" + timeSeconds + " ";
            } else {
                timeLabel = timeMinutes + ":" + timeSeconds + " ";
            }
            $(field).html(timeLabel);
        }
        displayTime(".ticketing_timer_update", timeLeft);

        var updateCountdownId = null; //This global variable is a hack for #7215. Sorry

        function updateCountdown() {
            now = new Date();
            if (now < endTime) {
                displayTime(".ticketing_timer_update", (endTime - now) / 1000);
                updateCountdownId = window.setTimeout(updateCountdown, 1000);
            } else {
                alert(ticketingTimer.data('sessionexpired'));
                document.location.href = ticketingTimer.data('url');
            }
        }

        updateCountdownId = window.setTimeout(updateCountdown, 1000);
    }

    var alreadySubmited = false;
    $('.payment-div form').on("submit", function(e) {
        var theForm = this;
        e.preventDefault();
        if (alreadySubmited) {
            return e.preventDefault();
        }
        alreadySubmited = true;
        var btn = $(this).find('.c-btn');
        var oldVal = btn.val();
        btn.text('Paiement en cours ...');
        btn.attr('disabled', 'disabled');

        if ($('input[name="paymentForm[PaymentTypeChoice][payment_type_choice]"]:checked').val() == 1) {
            hfields.createToken(function (result) {
                if (result.execCode == '0000') {
                    document.getElementById('paymentForm_Cb_hf_token').value = result.hfToken;
                    document.getElementById('paymentForm_Cb_card_code').value = result.cardCode;
                    document.getElementById('paymentForm_Cb_card_expiry_date').value = result.cardValidityDate;
                    document.getElementById('paymentForm_Cb_card_type').value = result.cardType;
                    document.getElementById('paymentForm_Cb_card_selected_brand').value = result.selectedBrand;
                    theForm.submit();
                    return true;
                } else {
                    btn.removeAttr('disabled');
                    btn.text(oldVal);
                    alreadySubmited = false;
                }
            });
        }
        else {
            theForm.submit();
            return true;
        }

        return false;
    });

    if($('.choice-payment-type').length) {
        var style = {
            "input": {
                "font-size": "16px",
                "color": "#171717",
                "line-height": "45px",
                "background-color": "transparent"
            }
        };
        var hfields = dalenys.hostedFields({
            key: {
                id: $('.cb-form').data('api-key-id'),
                value: $('.cb-form').data('api-key')
            },
            fields: {
                'brand': {
                    id: 'brand-container',
                    useInlineSelection: true,
                    isCbPreferredNetwork: true,
                },
                'card': {
                    id: 'card-container',
                    style: style,
                    enableAutospacing: true,
                },
                'expiry': {
                    id: 'expiry-container',
                    style: style

                },
                'cryptogram': {
                    id: 'cvv-container',
                    style: style,
                    onFocus: function(e){
                        $('.ccv-info').show()
                    },
                    onBlur: function(e){
                        $('.ccv-info').hide();
                    }
                }
            }
        });
        hfields.load();
    }
});
